<template>
  <v-card raised class="nospace">
    <loading :active.sync="loading" />

    <v-card-text class="nospace">
      <v-layout>
        <v-flex md3></v-flex>
        <v-flex xs6 md6 pt-3 style="color: grey; text-align: center"
          >*Drivers are filtered on the availability(free), working country
          {{ currency }} and Category - {{ subCat }}</v-flex
        >
        <v-flex md3></v-flex>
      </v-layout>
      <v-card-text class="nospace">
        <v-card-title class="bg-clr">
          <v-btn fab flat @click.native="goback">
            <v-icon class="pointer" v-ripple>arrow_back</v-icon>
          </v-btn>
          <span style="font-size: 20px">List of Drivers</span>
        </v-card-title>
        <v-layout style="border-top: 1px solid #efefef" class="p-16">
          <v-flex xs5 md5>
            <v-text-field
              v-model="search"
              label="Search by driver id, driver name and driver mobile number"
              @keypress.enter="searchText"
              style="max-width: 500px, margin-right: 0, margin-left: 50px"
            ></v-text-field>
          </v-flex>
          <v-flex></v-flex>

          <v-flex xs1 pt-2 class="reset-btn">
            <v-btn color="white" @click="searchText()" :loading="loading">
              <v-icon small>search</v-icon>&nbsp;Search
            </v-btn>
          </v-flex>
          <v-flex
            xs1
            md1
            pt-2
            class="reset-btn"
            style="padding-left: 2px !important"
          >
            <v-btn
              class="reset-btn-color"
              flat
              prepend-icon
              color="orange darken-1"
              @click.native="reset()"
            >
              Reset
              <v-icon color="orange">donut_large</v-icon>
            </v-btn>
          </v-flex>
          <div class="pt-1">
            <notifyDrivers />
          </div>
          <!-- <v-btn
           
            style="background: orange !important"
            flat
             @click="open"
           
            prepend-icon
            color="white darken-1"
            
            
            >Notify</v-btn>-->
        </v-layout>
      </v-card-text>
      <v-data-table
        id="TransporterListTable"
        hide-actions
        :headers="headers"
        :items="items"
        :loading="loading"
      >
        <template slot="items" slot-scope="props">
          <td>
            <v-flex xs12 class="ml-5" sm6 md6 v-if="props.item.profilePicURL">
              <a
                v-if="props.item.profilePicURL"
                :href="props.item.profilePicURL"
                title="Profile Picture"
                target="_blank"
                class
              >
                <img
                  :src="props.item.profilePicURL"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>
            <v-flex xs12 class="ml-5" sm6 md6 v-else>
              <img
                src="https://lakewangaryschool.sa.edu.au/wp-content/uploads/2017/11/placeholder-profile-sq.jpg"
                height="70px"
                width="70px"
                class="thumb-img"
                alt
              />
            </v-flex>
          </td>
          <td
            v-if="props.item.firstName != null && props.item.lastName != null"
          >
            Driver Id - {{ props.item.driverId }}
            <br />
            {{ props.item.firstName + " " + props.item.lastName }}
          </td>
          <td v-else>Driver Id - {{ props.item.driverId }}</td>
          <td
            v-if="props.item.countryCode != null && props.item.phoneNo != null"
          >
            {{ props.item.countryCode + "-" + props.item.phoneNo }}
          </td>
          <td v-else>-</td>
          <td>{{ props.item.completeAssignCount }}</td>

          <td v-if="props.item.assignmentData[0]">
            {{ props.item.assignmentData[0].destinationCity || "-" }}
          </td>
          <td v-else>-</td>
          <td>
            {{ props.item.truckPrefix || "-" }} {{ props.item.truckNumber }}
          </td>
          <td>{{ getTime(props.item.createdAt) }}</td>
          <td v-if="props.item.availabilityStatus">
            <assignDriver
              :driverId="props.item.driverId.toString()"
              :truckNo="props.item.truckNumber"
              :jobCurrency="currency"
              :truckprefix="props.item.truckPrefix"
              :isMilestone="isMilestone"
              :ownerType="'Owner'"
              @assign-count="
                error =
                  'Driver cannot be assigned as there is no assignment left'
              "
              :driver_id="props.item._id"
              :driverDetails="props.item"
            />
          </td>
          <td v-else><span style="color: green">Assigned</span></td>
        </template>
      </v-data-table>
      <span class="page-count-span totalpages"
        >Total Pages - {{ totalPages }}</span
      >

      <PaginationButtons
        :url="`/newDriverFlow/upperTransporterOwnerAndDriver`"
        :payload="payload"
        :callback="callback"
        :componentKey="componentKey"
        v-on:pagination-payload="paginationPayload"
      />
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
    </v-card-text>
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
        <v-card-actions class="pt-0">
          <v-btn color="primary darken-1" text @click.native="agree">Yes</v-btn>
          <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </v-card>
</template>
<script>
import _ from "lodash";
import assignDriver from "@/components/driverfilters/assignDriver";
import { newAssignOwnerDriverList } from "@/constants/datatable-headers.js";
import { TruckPrefix } from "../../constants/constants";
import notifyDrivers from "@/components/driverfilters/notifyDrivers";
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import Loading from "vue-loading-overlay";
import { StorageKeys, TruckTypes } from "../../constants/constants";
import { async } from "q";
import { sendSMStoDriver } from "../../constants/api-urls";
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  created() {
    if (localStorage.getItem("searchFreedriver") !== null) {
      this.search = localStorage.searchFreedriver;
      this.searchText();
    }
    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });
    const { id = null } = this.$route.params;
    this.id = Number(id);
    this.trucks = TruckTypes;

    this.$eventBus.$on("assignedDriver", (id) => {
      this.items.forEach((x, i) => {
        this.componentKey = this.componentKey + 1;
      });
    });

    this.prefixTrucks = TruckPrefix;
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }
    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `${token}`,
      },
    };
    this.t = config.headers.authorization;

    const url = `${this.constants.apiUrl}/driver/getPreferredLocation`;
    this.axios.get(url).then((response) => {
      if (response.status === 200) {
        response.data.data.forEach((e) => {
          this.preferredLocations.push({ value: e.name, text: e.name });
        });
      }
    });
  },
  components: {
    PaginationButtons,
    notifyDrivers,
    SuccessDialog,
    Loading,
    assignDriver,
  },
  props: {},

  data() {
    return {
      select: { status: "CHOICE", abbr: "CHOICE" },
      driverStatus: [
        { status: "ALL", abbr: "All drivers" },
        { status: "CHOICE", abbr: "Filtered drivers" },
      ],
      status: "",
      currency: "",
      destCity: null,
      subCat: null,
      items: [],
      trucks: [],

      title: null,
      message: "",

      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog: false,
      search: "",
      id: this.$route.params,
      isMilestone: false,
      prefixTrucks: [],
      truckPrefix: null,
      preferredLocations: [],
      preferredLocation: "",
      dialog: false,
      dialog2: false,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      loading: false,
      error: null,
      search: "",
      componentKey: 0,

      totalPages: "",

      payload: {
        jobId: parseInt(this.$route.params.id),
        tabOrder: parseInt(this.$route.params.tabOrder) || 1,
        skip: "0",
        limit: "15",
        status: "Owner",
      },

      headers: newAssignOwnerDriverList,
    };
  },
  watch: {
    select(val) {
      this.filterByStatus();
    },
  },
  methods: {
    goback() {
      this.$router.push(`/jobs/${this.$route.params.id}`);
    },
    getTime3(date) {
      return moment(date)("DD/MM/YYYY HH:mm ");
    },
    filterByStatus() {
      this.loading = true;
      if (this.search.trim().length > 0) {
        this.payload = {
          tabOrder: parseInt(this.$route.params.tabOrder) || 1,
          jobId: parseInt(this.$route.params.id),
          skip: "0",
          limit: "15",
          status: "Owner",
          searchText: this.search,
        };
      } else {
        this.payload = {
          tabOrder: parseInt(this.$route.params.tabOrder) || 1,
          jobId: parseInt(this.$route.params.id),
          skip: "0",
          limit: "15",
          status: "Owner",
        };
      }
    },
    open() {
      this.dialog = true;
      this.title = "Verification";
      this.message = "Are you sure want to notify all  drivers listed below ?";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      let url = sendSMStoDriver;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { jobId: this.$route.params.id };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;
          this.x.loading = false;
          this.dialog = false;
        },
        (error) => {
          this.loading = false;
          this.error = "Failed to update inquiry status";
        }
      );
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    closeMessage() {
      this.x.success = false;

      this.reset();
    },
    notifyDrivers() {
      let url = sendSMStoDriver;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { jobId: this.$route.params.id };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;
          this.x.loading = false;
          this.x.success = true;
          this.x.message = "Drivers notified";
        },
        (error) => {
          this.loading = false;
          this.error = "Failed to fetch data";
        }
      );
    },
    reset() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      if (this.search.trim() != "") {
        localStorage.removeItem("searchFreedriver", this.search);
        this.payload = {
          tabOrder: parseInt(this.$route.params.tabOrder) || 1,
          jobId: parseInt(this.$route.params.id),
          skip: "0",
          limit: "15",
          status: "Owner",
        };
        this.search = "";

        this.loading = true;
      }
    },
    searchText: function () {
      if (this.search.trim().length > 0) {
        localStorage.setItem("searchFreedriver", this.search);
        this.loading = true;
        this.payload = {
          tabOrder: parseInt(this.$route.params.tabOrder) || 1,
          jobId: parseInt(this.$route.params.id),
          skip: "0",
          limit: "15",
          searchText: this.search,
          status: "Owner",
        };
      }
    },
    getTime(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    paginationPayload(event) {
      this.payload = event;
      this.loading = event;
    },

    callback(res) {
      this.loading = false;
      if (res.jobDetail.customerCurrency == "AED") {
        this.currency = "UAE";
      }
      if (res.jobDetail.customerCurrency == "SAR") {
        this.currency = "KSA";
      }
      if (res.jobDetail.customerCurrency == "PKR") {
        this.currency = "PAK";
      }

      this.destCity = res.jobDetail.destinationCity;
      this.isMilestone = res.jobDetail.milestoneId ? true : false;
      this.trucks.forEach((k) => {
        if (k.value === res.jobDetail.truckType.toString()) {
          this.subCat = k.text;
        }
      });
      this.items = res.list;

      this.$emit("driver-list", this.items);
      this.totalPages = Math.ceil(res.totalData / 15);

      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.v-card {
  padding: 15px;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.direction {
  display: flex;
  float: right;
}
</style>
