<template>
  <v-card raised class="nospace">
    <v-layout>
      <v-flex md3></v-flex>
    </v-layout>
    <loading :active.sync="loading" />
    <v-card-text class="nospace">
      <v-layout style="border-top: 1px solid #efefef" class="p-16">
        <v-flex xs3 class>
          <v-select
            :items="AssignmentId"
            label="Assignment Id"
            item-text="assignmentId"
            item-value="_id"
            v-model="ids"
            @change="assigmentsFilter"
            class="pt-0 currencyTitle"
            single-line
          ></v-select>
        </v-flex>

        <v-flex class="pr-3" xs12 md3></v-flex>

        <v-flex xs1 class="reset-btn">
          <v-card-actions class="justify-center">
            <v-btn
              class="reset-btn-color"
              flat
              prepend-icon
              color="orange darken-1"
              @click.native="reset()"
            >
              Reset
              <v-icon color="orange">donut_large</v-icon>
            </v-btn>
          </v-card-actions>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-text class="nospace">
      <v-data-table
        id="ViewBids"
        hide-actions
        :headers="headers"
        :items="items"
        :loading="loading"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.drivers[0].driverId }}</td>
          <td>{{ props.item.drivers[0].name }}</td>

          <td>{{ props.item.bidAmount }} {{ props.item.bidCurrency }}</td>
          <td v-if="props.item.bidDate">{{ getTime(props.item.bidDate) }}</td>
          <td v-else>NA</td>

          <td>{{ props.item.advanceAmount }} {{ props.item.bidCurrency }}</td>
          <td v-if="props.item.status == 'PENDING'">
            <span class="pending">{{ props.item.status }}</span>
          </td>
          <td v-else-if="props.item.status == 'ACCEPTED'">
            <span class="accepted">{{ props.item.status }}</span>
          </td>
          <td v-else-if="props.item.status == 'REJECTED'">
            <span class="rejected">{{ props.item.status }}</span>
          </td>
          <td v-else>{{ props.item.status }}</td>

          <td
            v-if="
              !props.item.drivers[0].availabilityStatus &&
              props.item.status == 'PENDING'
            "
          >
            Driver is already assign on another job
          </td>
          <td
            v-else-if="
              !props.item.isAccept &&
              !props.item.isAssign &&
              props.item.status == 'PENDING'
            "
          >
            <v-btn
              target="_blank"
              color="orange darken-3"
              class="white--text"
              @click="
                open(props.item.assignmentId, props.item.uniqueId, props.item)
              "
              >Accept</v-btn
            >
          </td>
          <td
            v-else-if="
              !props.item.isAssign &&
              props.item.isAccept &&
              props.item.status == 'ACCEPTED'
            "
          >
            <BidAssignDriver
              :driverId="props.item.drivers[0].driverId.toString()"
              :driverDetails="props.item.drivers[0]"
              :truckNo="props.item.drivers[0].truckNumber"
              :truckprefix="props.item.drivers[0].truckPrefix"
              :advanceAmount="props.item.advanceAmount"
              :bidAmount="props.item.bidAmount"
              v-on:transport-job="bidSuccess"
              v-on:assign-count="errorAssignment()"
              v-on:bid-success="refreshBid"
              :address="props.item"
              :bidCurrency="props.item.bidCurrency"
              :assignNumber="props.item.assignNumber"
              :assignID="props.item.assignmentId"
              :ownerType="props.item.drivers[0].ownerType"
            />
          </td>
          <td v-else-if="props.item.isAssign && props.item.isAccept">
            <span class="assigned">Driver Assigned</span>
          </td>
          <td v-else-if="(props.item.status = 'REJECTED')">-</td>
        </template>
      </v-data-table>
      <span class="page-count-span totalpages"
        >Total Pages - {{ totalPages }}</span
      >
      <PaginationButtons
        :url="`/dmsAdmin/bidList`"
        :payload="payload"
        :callback="callback"
        :componentKey="componentKey"
        v-on:pagination-payload="paginationPayload"
      />
      <v-dialog
        v-model="dialog"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
      >
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text v-show="!!message" class="pa-4">{{
            message
          }}</v-card-text>
          <v-card-actions class="pt-0">
            <v-btn color="primary darken-1" text @click.native="agree"
              >Yes</v-btn
            >
            <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-snackbar
      :timeout="6000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </v-card>
</template>
<script>
import { StorageKeys, TruckTypes } from "../../constants/constants";
import { TruckPrefix } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import { newAssignViewBidList } from "@/constants/datatable-headers.js";
import ErrorBox from "@/components/Common/ErrorBox";
import BidAssignDriver from "@/components/driverfilters/BidAssignDriver";
import Loading from "vue-loading-overlay";
import moment from "moment";
import { bidResponse, tenderSectionApiUrl } from "../../constants/api-urls";
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  mounted() {
    EventBus.$on("assign-id", function (e) {});
  },
  created() {
    this.assigments();
    if (this.$route.query.x) {
      this.ids = this.$route.query.x;
      this.assigmentsFilter(this.$route.query.x);
    }
  },
  components: {
    PaginationButtons,
    BidAssignDriver,
    Loading,
  },
  watch: {
    select(val) {
      this.filterByStatus();
    },
  },

  props: {},
  data() {
    return {
      error: null,
      isAssigned: false,
      data: {},
      isAcceptBid: false,
      message: "",
      title: "",
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      destCity: "",
      dialog: false,
      bidID: "",
      items: [],
      error: "",
      AssignmentId: [],
      status: "",
      loading: false,
      totalPages: "",
      search: "",
      ids: "",

      componentKey: 0,
      totalPages: "",

      payload: {
        assignId: this.$route.query.x,
        skip: "0",
        limit: "15",
      },
      headers: newAssignViewBidList,
    };
  },
  methods: {
    bidSuccess(event) {},
    errorAssignment() {
      this.error = "Driver cannot be assigned as there is no assignment left";
    },
    refreshBid(event) {
      this.componentKey = this.componentKey + 1;
      for (var i = 0; i < this.items.length; i++) {
        if (event.driverId === this.items[i].driverId) {
          this.items[i].isAssign = true;
          this.items[i].isAccept = true;
          this.items[i].status = "ASSIGNED";
        }
      }
    },

    assigmentsFilter(val) {
      this.payload = {
        skip: "0",
        limit: "15",
        assignId: val,
      };
    },

    open(val, type, data) {
      this.ids = val;
      this.uniqueBidId = type;
      this.data = data;

      this.dialog = true;
      this.title = "Confirmation";
      this.message = "Are you sure want to accept?";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.accept(this.ids, this.uniqueBidId, this.data);
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },

    accept(assignId, BidId, data) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }

      this.processing = true;
      this.loading = true;
      let url = bidResponse.acceptDriverBid;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: this.ids,
        uniqueBidId: BidId,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dialog = false;
          this.identify(localStorage.getItem("email"));
          this.track("Bid Accepted", {
            "Job Id": this.$route.params.id,
            "Assignment Id": data.assignNumber || "",
            "Bid Date": data.bidDate || "",
            "Working Country": data.drivers[0].workingCountry || "",
            "Driver Id": data.drivers[0].driverId || "",
            "Driver Name": data.drivers[0].name || "",
            "Bid Amount": data.bidAmount || "",
            "Advance Amount": data.advanceAmount || "",
          });

          this.isAcceptBid = true;
          this.processing = false;
          (this.payload = {
            assignId: this.ids || this.$route.query.x,
            skip: "0",
            limit: "15",
          }),
            (this.loading = false);
        },
        (error) => {
          this.error = error.response.data.message;
          this.dialog = false;

          this.loading = false;
        }
      );
    },
    getTime(time) {
      return moment(time).format("DD/MM/YYYY HH:mm");
    },
    getResponse() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.processing = true;
      let url = tenderSectionApiUrl.viewBid;
      let config = {
        headers: {},
      };
      let body = {
        jobId: this.$route.params.id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;
          this.processing = false;
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch Assignments";
          this.processing = false;
          this.loading = false;
        }
      );
    },

    getList() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.processing = true;
      this.loading = true;
      let url = bidResponse.bidList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: this.ids,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;
          this.processing = false;
          this.loading = false;
        },
        (error) => {
          this.processing = false;
          this.loading = false;
        }
      );
    },
    assigments() {
      this.loading = true;
      const data = {
        JobId: this.$route.params.id,
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/dmsAdmin/assignments2`, data, config).then(
        (response) => {
          const { data } = response.data;
          this.count = response.data.data.count;
          this.AssignmentId = response.data.data.data;
          this.loading = false;
        },
        (error) => {
          this.error = error.response.data.message;
          this.loading = false;
        }
      );
    },
    reset() {
      if (this.ids) {
        this.ids = this.AssignmentId[0]._id;
        this.payload = {
          assignId: this.AssignmentId[0]._id,
          skip: "0",
          limit: "15",
        };
      }
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
    getTime(time) {
      return moment(time).format("DD/MM/YYYY");
    },
    getDateTime(time) {
      return moment(time).format("DD/MM/YYYY HH:mm");
    },
    callback(res) {
      this.loading = false;
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);

      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  background: orange !important;
  font-weight: bold;
}
.count {
  background-color: orange !important;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
}
.assigned {
  background-color: orange !important;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
}
.pending {
  background-color: grey !important;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 3px 2px 3px;
  border-radius: 5px;
}
.rejected {
  background-color: red !important;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
}
.accepted {
  background-color: green;
  color: white !important;
  font-size: 14px !important;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
}
</style>
