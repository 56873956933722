<template>
  <v-card raised class="nospace">
    <v-layout>
      <v-flex md3></v-flex>
      <v-flex xs6 md6 style="padding:0 5px;color:grey;text-align: center"
        >*Drivers are filtered on the basis of Desination City -
        {{ destCity }} and Category - {{ subCat }}</v-flex
      >
      <v-flex md3></v-flex>
    </v-layout>
    <loading :active.sync="loading" />
    <v-card-text class="nospace">
      <v-layout style="border-top: 1px solid #efefef" class="p-16">
        <v-flex xs5>
          <v-text-field
            v-model="search"
            label="Search by driver name ,driver ID and driver phone number "
            @keypress.enter="searchText"
            style="max-width: 500px, margin-right: 0, margin-left: 50px"
          ></v-text-field>
        </v-flex>
        <v-flex class="pr-3" xs12 md3></v-flex>
        <v-flex xs12 class="pr-4" sm6 md4>
          <v-select
            v-model="select"
            :items="driverStatus"
            item-text="abbr"
            item-value="status"
            label="Filter by Driver Status"
            persistent-hint
            return-object
            single-line
            @change="filterByStatus"
          />
        </v-flex>
        <v-flex xs1 class="reset-btn">
          <v-card-actions class="justify-center">
            <v-btn
              class="reset-btn-color"
              flat
              prepend-icon
              color="orange darken-1"
              @click.native="reset()"
            >
              Reset
              <v-icon color="orange">donut_large</v-icon>
            </v-btn>
          </v-card-actions>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-text class="nospace">
      <v-data-table
        id="ReverseLogistics"
        hide-actions
        :headers="headers"
        :items="items"
        :loading="loading"
      >
        <template slot="items" slot-scope="props">
          <td>
            <v-flex xs12 class="ml-5" sm6 md6>
              <a
                v-if="props.item.profilePicURL"
                :href="props.item.profilePicURL"
                title="Profile Picture"
                target="_blank"
                class
              >
                <img
                  :src="props.item.profilePicURL"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>
          </td>
          <td>
            Driver Id - {{ props.item.driverId }}
            <br />
            {{ props.item.driverName }}
          </td>

          <td>
            {{ props.item.countryCode }} {{ props.item.driverPhoneNumber }}
          </td>
          <td>{{ props.item.jobId }}</td>
          <td>{{ props.item.JobSourceCity }}</td>
          <td>{{ props.item.JobDestinationCity }}</td>
          <td>{{ getDateTime(props.item.JobStartDate) }}</td>
          <td>{{ getTime(props.item.JobEndDate) }}</td>
        </template>
      </v-data-table>
      <span class="page-count-span">Total Pages - {{ totalPages }}</span>
      <PaginationButtons
        :url="`/dmsAdmin/reverseLogistics`"
        :payload="payload"
        :callback="callback"
        :componentKey="componentKey"
        v-on:pagination-payload="paginationPayload"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import { StorageKeys, TruckTypes } from "../../constants/constants";
import { TruckPrefix } from "../../constants/constants";
import Loading from "vue-loading-overlay";
import moment from "moment";
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  created() {
    if (localStorage.getItem("logisticsSearch") !== null) {
      this.search = localStorage.logisticsSearch;
      this.searchText();
    }
    this.trucks = TruckTypes;

    this.prefixTrucks = TruckPrefix;
    const url = `${this.constants.apiUrl}/driver/getPreferredLocation`;
    this.axios.get(url).then((response) => {
      if (response.status === 200) {
        response.data.data.forEach((e) => {
          this.preferredLocations.push({ value: e.name, text: e.name });
        });
      }
    });
  },
  components: {
    PaginationButtons,
    Loading,
  },
  watch: {
    select(val) {
      this.filterByStatus();
    },
  },

  props: {},
  data() {
    return {
      destCity: "",
      subCat: "",
      select: { status: "CHOICE", abbr: "CHOICE" },
      driverStatus: [
        { status: "ALL", abbr: "All drivers" },
        { status: "CHOICE", abbr: "Filtered drivers" },
      ],
      status: "",
      loading: false,
      totalPages: "",
      search: "",

      componentKey: 0,

      totalPages: "",

      payload: {
        jobId: this.$route.params.id,
        skip: "0",
        limit: "15",
        status: "CHOICE",
      },
      prefixTrucks: [],
      truckPrefix: null,
      preferredLocations: [],
      preferredLocation: [],
      jobStartDatePicker: null,
      jobStartDateBool: false,
      startDate: 0,
      headers: [
        {
          text: "Profile Picture",
          value: "driver",
          sortable: false,
          class: "table-header-item",
        },
        {
          text: "Driver Details",
          value: "driver",
          sortable: false,
          class: "table-header-item",
        },

        {
          text: "Mobile Number",
          value: "driverPhoneNumber",
          sortable: false,
          class: "table-header-item",
        },
        {
          text: "Current Job ID",
          value: "jobId",
          sortable: false,
          class: "table-header-item",
        },

        {
          text: "Source City",
          value: "JobSourceCity",
          sortable: false,
          class: "table-header-item",
        },
        {
          text: "Destination City",
          value: "JobDestinationCity",
          sortable: false,
          class: "table-header-item",
        },
        {
          text: "Job Start Date",
          value: "JobStartDate",
          sortable: false,
          class: "table-header-item",
        },
        {
          text: "Estimated Job End Date",
          value: "JobEndDate",
          sortable: false,
          class: "table-header-item",
        },
      ],
      items: [],
    };
  },
  methods: {
    reset() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      if (this.search.trim() != "") {
        localStorage.removeItem("logisticsSearch", this.search);
        this.payload = {
          jobId: this.$route.params.id,
          skip: "0",
          limit: "15",
          status: this.select.status,
        };
        this.search = "";
        this;

        this.loading = true;
      }
    },
    filterByStatus() {
      this.loading = true;
      if (this.search.trim().length > 0) {
        this.payload = {
          jobId: this.$route.params.id,
          skip: "0",
          limit: "15",
          status: this.select.status,
          searchText: this.search,
        };
      } else {
        this.payload = {
          jobId: this.$route.params.id,
          skip: "0",
          limit: "15",
          status: this.select.status,
        };
      }
    },
    searchText: function() {
      if (this.search.trim().length > 0) {
        localStorage.setItem("logisticsSearch", this.search);
        this.loading = true;
        this.payload = {
          jobId: this.$route.params.id,

          skip: "0",
          limit: "15",
          searchText: this.search,
          status: this.select.status,
        };
      }
    },
    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
    getTime(time) {
      return moment(time).format("DD/MM/YYYY");
    },
    getDateTime(time) {
      return moment(time).format("DD/MM/YYYY HH:mm");
    },
    callback(res) {
      this.loading = false;
      this.destCity = res.jobDetail.destinationCity;
      this.trucks.forEach((k) => {
        if (k.value === res.jobDetail.truckType.toString()) {
          this.subCat = k.text;
        }
      });

      this.items = res.list;

      this.totalPages = Math.ceil(res.totalData / 15);

      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  background: orange !important;
  font-weight: bold;
}
</style>
