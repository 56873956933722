<template>
  <v-card raised class="nospace">
    <loading :active.sync="loading" />

    <v-card-text class="nospace">
      <v-layout>
        <v-flex md3></v-flex>
        <v-flex xs6 md6 pt-3 style="color: grey; text-align: center"
          >*Drivers are filtered on the availability(free), working country
          {{ currency }} and Category - {{ subCat }}</v-flex
        >
        <v-flex md3></v-flex>
      </v-layout>
      <v-card-text class="nospace">
        <v-card-title class="bg-clr">
          <v-btn fab flat @click.native="goback">
            <v-icon class="pointer" v-ripple>arrow_back</v-icon>
          </v-btn>
          <span style="font-size: 20px">List of Transporter's Driver</span>
        </v-card-title>
        <v-layout style="border-top: 1px #efefef">
          <v-flex xs4 md3 pt-3>
            <div>
              <ejs-multiselect
                id="multiselect-checkbox"
                :dataSource="transporters"
                :fields="fields"
                :placeholder="checkWaterMark"
                :mode="multiMode"
                v-model="transporterId"
                :closePopupOnSelect="true"
                :showClearButton="false"
                :popupHeight="popHeight"
                :showDropDownIcon="showDropDownIcon"
                :filterBarPlaceholder="filterPlaceholder"
                :enableSelectionOrder="enableSelectionOrder"
              ></ejs-multiselect>
              <!-- <v-autocomplete
                style="background-color: white !important"
                attach
                :items="transporters"
                v-model="transporterId"
                label="All Transporter"
                @change="searchTransporter()"
                single-line
                multiple
                required
              ></v-autocomplete> -->
            </div>
          </v-flex>
          <v-flex xs5 md5>
            <v-text-field
              v-model="search"
              label="Search by driver id, driver name and driver mobile number"
              @keypress.enter="searchText"
              style="max-width: 500px, margin-right: 0, margin-left: 20px"
            ></v-text-field>
          </v-flex>
          <v-flex></v-flex>
          <v-flex xs1 pt-2 class="reset-btn">
            <v-btn color="white" @click="searchText()" :loading="loading">
              <v-icon small>search</v-icon>&nbsp;Search
            </v-btn>
          </v-flex>
          <v-flex xs1 md1 pt-2 class="reset-btn">
            <v-card-actions class="justify-center">
              <v-btn
                class="reset-btn-color"
                flat
                prepend-icon
                color="orange darken-1"
                @click.native="reset()"
              >
                Reset
                <v-icon color="orange">donut_large</v-icon>
              </v-btn>
            </v-card-actions>
          </v-flex>
          <v-flex md1 xs2 pt-1>
            <notifyDrivers :isTransporter="true" />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-data-table
        id="TransporterListTable"
        hide-actions
        :headers="headers"
        :items="items"
        :loading="loading"
      >
        <template slot="items" slot-scope="props">
          <td>
            <v-flex xs12 class="ml-5" sm6 md6 v-if="props.item.profilePicURL">
              <a
                v-if="props.item.profilePicURL"
                :href="props.item.profilePicURL"
                title="Profile Picture"
                target="_blank"
                class
              >
                <img
                  :src="props.item.profilePicURL"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>
            <v-flex xs12 class="ml-5" sm6 md6 v-else>
              <img
                src="https://lakewangaryschool.sa.edu.au/wp-content/uploads/2017/11/placeholder-profile-sq.jpg"
                height="70px"
                width="70px"
                class="thumb-img"
                alt
              />
            </v-flex>
          </td>
          <td
            v-if="props.item.firstName != null && props.item.lastName != null"
          >
            Driver Id - {{ props.item.driverId }}
            <br />
            {{ props.item.firstName + " " + props.item.lastName }}
          </td>
          <td v-else>Driver Id - {{ props.item.driverId }}</td>
          <td
            v-if="props.item.countryCode != null && props.item.phoneNo != null"
          >
            {{ props.item.countryCode + "-" + props.item.phoneNo }}
          </td>
          <td v-else>-</td>
          <td v-if="props.item.transporterData">
            {{ getTransporterName(props.item.transporterData) }}
          </td>
          <td v-else>{{ props.item.transporterName || "-" }}</td>
          <td>{{ props.item.completeAssignCount }}</td>

          <td v-if="props.item.assignmentData[0]">
            {{ props.item.assignmentData[0].destinationCity || "-" }}
          </td>
          <td v-else>-</td>
          <td>
            {{ props.item.truckPrefix || "-" }} {{ props.item.truckNumber }}
          </td>
          <td v-if="props.item.createdAt">
            {{ getTime(props.item.createdAt) }}
          </td>
          <td v-else>-</td>
          <td v-if="props.item.driverId && props.item.availabilityStatus">
            <assignDriver
              :driverId="props.item.driverId.toString()"
              :truckNo="props.item.truckNumber"
              :jobCurrency="currency"
              :truckprefix="props.item.truckPrefix"
              isMilestone="isMilestone"
              :ownerType="'Transporter'"
              :ownerId="transporterId"
              :driverDetails="props.item"
              @assign-count="
                error =
                  'Driver cannot be assigned as there is no assignment left'
              "
            />
          </td>
          <td v-else><span style="color: green"> Assigned</span></td>
        </template>
      </v-data-table>
      <span class="page-count-span totalpages"
        >Total Pages - {{ totalPages }}</span
      >

      <PaginationButtons
        :url="`/newDriverFlow/upperTransporterOwnerAndDriver`"
        :payload="payload"
        :callback="callback"
        :componentKey="componentKey"
        v-on:pagination-payload="paginationPayload"
      />
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
    </v-card-text>
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
        <v-card-actions class="pt-0">
          <v-btn color="primary darken-1" text @click.native="agree">Yes</v-btn>
          <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </v-card>
</template>
<script>
import _ from "lodash";
import assignDriver from "@/components/driverfilters/assignDriver";
import { TruckPrefix } from "../../constants/constants";
import notifyDrivers from "@/components/driverfilters/notifyDrivers";
import { newAssignTransporterDriverList } from "@/constants/datatable-headers.js";
import moment from "moment";
import { transporter, sendSMStoDriver } from "../../constants/api-urls";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import Loading from "vue-loading-overlay";
import { StorageKeys, TruckTypes } from "../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import Vue from "vue";
import {
  MultiSelectPlugin,
  CheckBoxSelection,
} from "@syncfusion/ej2-vue-dropdowns";
Vue.use(MultiSelectPlugin);
export default {
  provide: {
    multiselect: [CheckBoxSelection],
  },
  created() {
    if (localStorage.getItem("searchFreedriver") !== null) {
      this.search = localStorage.searchFreedriver;
      this.searchText();
    }
    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });
    const { id = null } = this.$route.params;
    this.id = Number(id);
    this.trucks = TruckTypes;

    this.$eventBus.$on("assignedDriver", (id) => {
      this.items.forEach((x, i) => {
        this.componentKey = this.componentKey + 1;
      });
    });

    this.prefixTrucks = TruckPrefix;
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }
    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `${token}`,
      },
    };
    this.t = config.headers.authorization;

    const url = `${this.constants.apiUrl}/driver/getPreferredLocation`;
    this.axios.get(url).then((response) => {
      if (response.status === 200) {
        response.data.data.forEach((e) => {
          this.preferredLocations.push({ value: e.name, text: e.name });
        });
      }
    });
  },
  components: {
    PaginationButtons,
    notifyDrivers,
    SuccessDialog,
    Loading,
    assignDriver,
  },
  props: {},

  data() {
    return {
      select: { status: "CHOICE", abbr: "CHOICE" },
      enableSelectionOrder: false,
      driverStatus: [
        { status: "ALL", abbr: "All drivers" },
        { status: "CHOICE", abbr: "Filtered drivers" },
      ],
      fields: { text: "text", value: "value" },
      checkWaterMark: "Select Transporters",
      multiMode: "CheckBox",
      popHeight: "350px",
      showDropDownIcon: true,
      filterPlaceholder: "Search Transporters",
      status: "",
      transporters: [],
      transporterName: "",
      isMilestone: false,
      transporterId: [],
      currency: "",
      destCity: null,
      subCat: null,
      items: [],
      trucks: [],

      title: null,
      message: "",

      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog: false,
      search: "",
      id: this.$route.params,
      prefixTrucks: [],
      truckPrefix: null,
      preferredLocations: [],
      preferredLocation: "",
      dialog: false,
      dialog2: false,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      loading: false,
      error: null,
      search: "",
      componentKey: 0,

      totalPages: "",

      payload: {
        jobId: parseInt(this.$route.params.id),
        tabOrder: parseInt(this.$route.params.tabOrder) || 1,
        skip: "0",
        limit: "15",
        status: "Transporter",
      },

      headers: newAssignTransporterDriverList,
    };
  },
  watch: {
    select(val) {
      this.filterByStatus();
    },
    transporterId(val) {
      console.log("--value", val);
      if (val) {
        if (val && val.length) {
          this.searchTransporter();
        } else {
          this.searchTransporter();
        }
      }
    },
  },
  methods: {
    goback() {
      this.$router.push(`/jobs/${this.$route.params.id}`);
    },
    getTransporterName(data) {
      let name = [];
      data.filter((x) => {
        if (x.transporterName) {
          return name.push(" " + x.transporterName + " ");
        }
      });
      return name.toString();
    },
    getTransporterList() {
      this.userId = localStorage.getItem("userId");
      let url = transporter.transporterForDriverDetail;
      delete this.axios.defaults.headers.common["token"];
      let body = {
        workingCountry: this.currency,
      };
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;

          response.data.data.list.forEach((e) => {
            this.transporters.push({ value: e._id, text: e.transporterName });
          });
        },
        (error) => {
          this.loading = false;
          this.x.error = "Failed to fetch transporters";
        }
      );
    },
    getTime3(date) {
      return moment(date).format("DD/MM/YYYY HH:mm ");
    },
    searchTransporter() {
      this.loading = true;
      if (this.transporterId.length) {
        if (this.search.trim().length > 0) {
          this.payload = {
            tabOrder: parseInt(this.$route.params.tabOrder) || 1,
            jobId: parseInt(this.$route.params.id),
            skip: "0",
            limit: "15",
            status: "Transporter",
            searchText: this.search,
            isFilter: true,
            transporterId: this.transporterId,
          };
        } else {
          this.payload = {
            tabOrder: parseInt(this.$route.params.tabOrder) || 1,
            jobId: parseInt(this.$route.params.id),
            skip: "0",
            limit: "15",
            status: "Transporter",
            isFilter: true,
            transporterId: this.transporterId,
          };
        }
      } else {
        if (this.search.trim().length > 0) {
          this.payload = {
            tabOrder: parseInt(this.$route.params.tabOrder) || 1,
            jobId: parseInt(this.$route.params.id),
            skip: "0",
            limit: "15",
            status: "Transporter",
            searchText: this.search,
          };
        } else {
          this.payload = {
            tabOrder: parseInt(this.$route.params.tabOrder) || 1,
            jobId: parseInt(this.$route.params.id),
            skip: "0",
            limit: "15",
            status: "Transporter",
          };
        }
      }
    },
    open() {
      this.dialog = true;
      this.title = "Verification";
      this.message = "Are you sure want to notify all  drivers listed below ?";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      let url = sendSMStoDriver;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { jobId: this.$route.params.id };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;
          this.x.loading = false;
          this.dialog = false;
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    closeMessage() {
      this.x.success = false;

      this.reset();
    },
    notifyDrivers() {
      let url = sendSMStoDriver;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { jobId: this.$route.params.id };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;
          this.x.loading = false;
          this.x.success = true;
          this.x.message = "Drivers notified";
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    reset() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      localStorage.removeItem("searchFreedriver");
      if (this.search.trim() != "" || this.transporterId.length) {
        this.payload = {
          tabOrder: parseInt(this.$route.params.tabOrder) || 1,
          jobId: parseInt(this.$route.params.id),
          skip: "0",
          limit: "15",
          status: "Transporter",
        };
        this.search = "";
        this.transporterId = [];
        this.loading = true;
      }
    },
    searchText: function () {
      if (this.search.trim().length > 0) {
        if (this.transporterId.length) {
          this.loading = true;
          this.payload = {
            tabOrder: parseInt(this.$route.params.tabOrder) || 1,
            jobId: parseInt(this.$route.params.id),
            skip: "0",
            limit: "15",
            searchText: this.search,
            status: "Transporter",
            isFilter: true,
            transporterId: this.transporterId,
          };
        } else {
          this.payload = {
            tabOrder: parseInt(this.$route.params.tabOrder) || 1,
            jobId: parseInt(this.$route.params.id),
            skip: "0",
            limit: "15",
            searchText: this.search,
            status: "Transporter",
          };
        }
      } else {
        if (this.transporterId.length) {
          this.loading = true;
          this.payload = {
            tabOrder: parseInt(this.$route.params.tabOrder) || 1,
            jobId: parseInt(this.$route.params.id),
            skip: "0",
            limit: "15",
            status: "Transporter",
            isFilter: true,
            transporterId: this.transporterId,
          };
        } else {
          this.payload = {
            tabOrder: parseInt(this.$route.params.tabOrder) || 1,
            jobId: parseInt(this.$route.params.id),
            skip: "0",
            limit: "15",
            status: "Transporter",
          };
        }
      }
    },
    getTime(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    paginationPayload(event) {
      this.payload = event;
      this.loading = event;
    },

    callback(res) {
      this.loading = false;
      if (res.jobDetail.customerCurrency == "AED") {
        this.currency = "UAE";
      }
      if (res.jobDetail.customerCurrency == "SAR") {
        this.currency = "KSA";
      }
      if (res.jobDetail.customerCurrency == "PKR") {
        this.currency = "PAK";
      }
      this.destCity = res.jobDetail.destinationCity;
      this.isMilestone = res.jobDetail.milestoneId ? true : false;
      this.trucks.forEach((k) => {
        if (k.value === res.jobDetail.truckType.toString()) {
          this.subCat = k.text;
        }
      });
      this.items = res.list;
      this.$emit("driver-list", this.items);
      this.totalPages = Math.ceil(res.totalData / 15);

      window.scrollTo(0, 0);
      this.getTransporterList();
    },
  },
};
</script>
<style lang="scss">
.e-multi-select-wrapper .e-close-icon-hide .e-down-icon {
  background: darkorange !important;
}
.e-input-in-wrap::after {
  background: darkorange !important;
}
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: darkorange !important;
}
.e-multiselect.e-input-group.e-control-wrapper.e-checkbox {
  width: 90% !important;
}
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before {
  background: darkorange !important;
}
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
  background: darkorange !important;
}
div.e-input-focus::before,
div.e-input-focus::after {
  background: darkorange !important;
}
input.e-dropdownbase::placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 500;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.v-card {
  padding: 15px;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.direction {
  display: flex;
  float: right;
}
</style>
