<template>
  <div class="log">
    <v-btn
      style="background: orange !important; margin-top: 17px !important"
      flat
      class="direction"
      @click.stop="dialog = true"
      @click="notifyDrivers"
      prepend-icon
      :disabled="isTransporter"
      color="white darken-1"
      >Notify</v-btn
    >

    <v-dialog v-model="dialog" max-width="55%" persistent>
      <v-form ref="form" onSubmit="return false;">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>Notify Drivers</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancel">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="nospace">
            <v-spacer class="spacerclass">
              <v-data-table
                v-model="selected"
                style="max-height: 50%"
                :headers="headers"
                :items="items"
                hide-actions
                item-key="driverId"
                select-all
                class="elevation-1"
              >
                <template slot="items" slot-scope="props">
                  <tr
                    :active="props.selected"
                    @click="props.selected = !props.selected"
                  >
                    <td>
                      <v-checkbox
                        :input-value="props.selected"
                        primary
                        hide-details
                      ></v-checkbox>
                    </td>
                    <td>{{ props.item.driverId }}</td>
                    <td>{{ props.item.name }}</td>
                    <td>
                      {{ props.item.countryCode + " " + props.item.phoneNo }}
                    </td>

                    <td>{{ props.item.truckNumber }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-spacer>
          </v-card-text>

          <v-toolbar fixed flat>
            <v-card-actions class="pt-0">
              <v-btn color="primary darken-1" text @click.native="agree"
                >Notify</v-btn
              >
              <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
            </v-card-actions>
            <div
              class="my-3"
              v-if="haserror"
              style="
                color: red;
                width: 50%;
                border: 0px;
                margin: auto;
                text-align: center;
              "
            >
              {{ x.error }}
            </div>
            <div
              class="my-3"
              v-if="this.success == true"
              style="
                color: green;
                width: 50%;
                border: 0px;
                margin: auto;
                text-align: center;
              "
            >
              Notified
            </div>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import { searchDriverByJob, sendSMStoDriver } from "../../constants/api-urls";
export default {
  data() {
    return {
      items: [],
      x: {
        error: null,
      },
      success: false,

      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      singleSelect: false,
      selected: [],
      drivers: [],
      headers: [
        {
          text: "Driver ID",
          sortable: false,
          align: "start",
          value: "driverId",
          class: "table-header-item",
        },
        {
          text: "Driver Name",
          sortable: false,
          value: "name",
          class: "table-header-item",
        },
        {
          text: "Mobile Number",
          sortable: false,
          value: "phoneNo",
          class: "table-header-item",
        },

        {
          text: "Truck Number",
          sortable: false,
          value: "truckNumber",
          class: "table-header-item",
        },
      ],
    };
  },
  props: {
    isTransporter: Boolean,
  },
  created() {},

  methods: {
    haserror() {
      return this.x.error !== null;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.items.slice();
    },
    cancel() {
      this.success = false;
      this.dialog = false;
      this.selected = [];
      this.drivers = [];
      this.x.error = "";
    },
    notifyDrivers() {
      let url = searchDriverByJob;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: this.$route.params.id,
        tabOrder: this.$route.params.tabOrder || "1",
        jobType: this.$route.params.jobType || "1",
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data.list;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.x.error = error.response.data.message;
        }
      );
    },
    agree() {
      this.selected.forEach((element) => {
        this.drivers.push(element.driverId);
      });

      let url = sendSMStoDriver;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: this.$route.params.id,

        drivers: this.drivers,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.data.statusCode == "200") {
            this.success = true;
            setTimeout(() => {
              this.cancel();
            }, 3000);
          }
        },
        (error) => {
          this.loading = false;
          this.x.error = error.response.data.message;
        }
      );
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.selectAll {
  position: absolute;
  right: 100px;
  top: 18px;
}
.direction {
  display: flex;
  float: right;
}
.spacerclass {
  max-height: 450px !important;
}
</style>
