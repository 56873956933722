import { render, staticRenderFns } from "./transportersList.vue?vue&type=template&id=186b3d1c&scoped=true&"
import script from "./transportersList.vue?vue&type=script&lang=js&"
export * from "./transportersList.vue?vue&type=script&lang=js&"
import style0 from "./transportersList.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./transportersList.vue?vue&type=style&index=1&id=186b3d1c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "186b3d1c",
  null
  
)

export default component.exports