<template>
  <div class="log">
    <v-btn
      @click.stop="
        assignmentList();
        getMilestones();
        fetchVendor();
        fetchAssets();
        checkTruckPrefix();
      "
      color="orange darken-3"
      :disabled="loading"
      class="white--text"
      >Assign</v-btn
    >

    <v-dialog v-model="dialog" max-width="55%" persistent>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title> Assign Driver </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false" @click="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-flex pt-1 class="assignment"
            >Assignment Left - {{ this.count }}</v-flex
          >
          <v-spacer class="assign-pop">
            <v-card-text class="nospace">
              <v-layout v-if="this.jobCurrency != 'PAK'">
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="showTracking"
                  row
                  single-line
                  :mandatory="true"
                >
                  <div>
                    <div class>
                      <v-layout v-if="this.assetId">
                        <v-radio
                          label="Show full tracking"
                          value="fullTracking"
                          color="orange"
                          class="col-md-6"
                        ></v-radio>
                        <v-radio
                          label="Show only current location"
                          value="onlyCurrentLocation"
                          color="orange"
                          class="col-md-6"
                        ></v-radio>
                      </v-layout>
                    </div>
                  </div>
                </v-radio-group>
              </v-layout>

              <v-layout align-baseline row wrap style="margin: 0px !important">
                <v-flex xs6 class>
                  <v-select
                    :items="AssignmentId"
                    label="Assignment Id"
                    v-model="ids"
                    class="pt-0 currencyTitle"
                    :rules="[rules.required]"
                    single-line
                  ></v-select>
                </v-flex>
                <v-flex xs6 class="pl-2">
                  <v-text-field
                    label="Driver rate for this assignment"
                    :suffix="this.radios"
                    type="number"
                    single-line
                    :rules="[rules.noWhiteSpace, rules.required]"
                    v-model="paymentAmount"
                  />
                </v-flex>

                <v-flex xs6 md6>
                  <v-select
                    label="Prefix*"
                    :items="prefixTrucks"
                    class="pt-0"
                    :readonly="this.$props.truckprefix"
                    v-model="truckPrefix"
                    single-line
                    :rules="[rules.required]"
                  />
                </v-flex>
                <v-flex xs6 md6>
                  <v-text-field
                    label="Truck Number"
                    type="number"
                    :readonly="this.$props.truckNo"
                    v-model="truckNumber"
                    single-line
                    :rules="[rules.required]"
                  />
                </v-flex>

                <v-flex xs6 md6 v-if="this.$props.ownerType == 'Owner'">
                  <v-combobox
                    :items="vendorNames"
                    v-model="vendorName"
                    label="Vendor's Name"
                    item-text="vendorName"
                    item-value="vendorName"
                    :disabled="!vendorNames.length"
                    required
                    @change="checkVendorName()"
                  ></v-combobox>
                </v-flex>
                <v-flex
                  xs12
                  md6
                  class="pr-2"
                  v-if="this.$props.ownerType == 'Transporter'"
                >
                  <div class="subheading muted caption">Vendor's Name*</div>
                  <div class="heading">
                    <v-combobox
                      class="pt-0 currencyTitle"
                      :items="vendorNames"
                      v-model="vendorName"
                      :disabled="!vendorNames.length"
                      item-text="transporterName"
                      item-value="transporterName"
                      @change="checkVendorName()"
                    ></v-combobox>
                  </div>
                  <span v-if="this.errorName"
                    >Please provide vendor's name</span
                  >
                </v-flex>
                <v-flex
                  xs5
                  md5
                  v-if="ownerType != 'ownTransporter'"
                  class="pl-2"
                  style="align-self: center"
                >
                  <v-autocomplete
                    :items="assets"
                    item-text="imeiNumber"
                    item-value="_id"
                    label="Hardware Attach"
                    single-line
                    v-model="assetId"
                  ></v-autocomplete>
                </v-flex>
                <v-flex
                  xs1
                  class="pr-2 reset-pos"
                  pt-2
                  v-if="driverType != 'ownTransporter' && assetId"
                >
                  <v-icon color="orange" class="pointer" @click="assetId = null"
                    >donut_large</v-icon
                  >
                </v-flex>

                <v-flex
                  xs6
                  md6
                  style
                  v-if="this.$props.ownerType == 'Transporter'"
                >
                  <v-text-field
                    label="Vendor's Address*"
                    type="text"
                    v-model="VendorAddress"
                    :value="VendorAddress"
                    :rules="[rules.required]"
                    :disabled="
                      vendorName
                        ? vendorName.vendorAddress || vendorName.address
                        : ''
                    "
                    single-line
                  />
                </v-flex>
                <v-flex xs6 md6 style v-if="this.$props.ownerType == 'Owner'">
                  <v-text-field
                    label="Vendor's Address"
                    type="text"
                    v-model="VendorAddress"
                    :value="VendorAddress"
                    :disabled="
                      vendorName
                        ? vendorName.vendorAddress || vendorName.address
                        : ''
                    "
                    single-line
                  />
                </v-flex>
                <v-flex xs6 md6 style v-if="this.$props.ownerType == 'Owner'">
                  <v-text-field
                    style="padding-left: 10px"
                    :label="
                      radios != 'PKR'
                        ? 'Vendor VAT Number'
                        : 'Vendor STRN Number'
                    "
                    type="text"
                    v-model="vendorVatNumber"
                    :value="vendorVatNumber"
                    :disabled="
                      vendorName
                        ? vendorName.vendorVatNumber || vendorName.vatNumber
                        : ''
                    "
                    counter
                    single-line
                    maxlength="20"
                  />
                </v-flex>
                <v-flex
                  xs6
                  md6
                  style
                  v-if="this.$props.ownerType == 'Transporter'"
                >
                  <v-text-field
                    style="padding-left: 10px"
                    :label="
                      radios != 'PKR'
                        ? 'Vendor VAT Number*'
                        : 'Vendor STRN Number*'
                    "
                    type="text"
                    v-model="vendorVatNumber"
                    :value="vendorVatNumber"
                    :rules="radios != 'PKR' ? [] : [rules.required]"
                    :disabled="
                      vendorName
                        ? vendorName.vendorVatNumber || vendorName.vatNumber
                        : ''
                    "
                    counter
                    single-line
                    maxlength="20"
                  />
                </v-flex>
                <v-flex xs6 md6 style>
                  <v-select
                    style="background-color: white !important"
                    :close-on-content-click="true"
                    attach
                    :items="countryCodes"
                    v-model="countryCode"
                    item-text="dialing_code"
                    :disabled="countryCode"
                    item-value="dialing_code"
                    label="Vendor's Country Code*"
                    :rules="[rules.required]"
                    single-line
                    required
                  ></v-select>
                </v-flex>
                <v-flex xs6 md6 style="padding-left: 10px">
                  <v-text-field
                    label="Vendor's Mobile"
                    type="text"
                    v-model="VendorNumber"
                    :disabled="VendorNumber"
                    :value="VendorNumber"
                    single-line
                    :rules="[rules.noWhiteSpace, rules.required]"
                  />
                </v-flex>

                <v-flex md12 v-if="!isMilestone">
                  <v-layout
                    row
                    v-if="this.milestoneTrack.milestone.length == 0"
                  >
                    <v-flex md11>
                      <v-select
                        v-model="milestone"
                        :items="milestoneItems"
                        item-text="pickupLocation"
                        item-value="_id"
                        label="Select Milestone"
                        @change="getChecklist"
                        persistent-hint
                        single-line
                      >
                        <template slot="item" slot-scope="data"
                          >{{ data.item.pickupLocation }} -
                          {{ data.item.dropOffLocation }}</template
                        >
                      </v-select>
                    </v-flex>
                    <v-flex md1>
                      <v-btn icon @click="clearMilestone">
                        <v-icon class="heading grey--text text--darken-4"
                          >close</v-icon
                        >
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-divider v-if="milestone"></v-divider>
                  <v-layout v-if="milestone">
                    <v-flex
                      xs12
                      md12
                      class="heading orange--text"
                      style="text-align: initial"
                    >
                      Milestone Information <br />
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="milestone">
                    <v-flex xs12 md12 class="cont">
                      <ul class="timelineHori">
                        <li v-for="(item, i) in checkList" :key="i">
                          <span class="postion-set">{{ item.name }}</span>
                        </li>
                      </ul>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs12>
                  <div
                    class="my-3"
                    v-if="haserror"
                    style="
                      color: red;
                      width: 50%;
                      border: 0px;
                      margin: auto;
                      text-align: center;
                    "
                  >
                    {{ error }}
                  </div>
                </v-flex>

                <v-flex xs12 style="text-align: center">
                  <v-btn
                    color="orange darken-3"
                    class="white--text"
                    @click="assignDriver()"
                    >Assign</v-btn
                  >
                </v-flex>

                <v-spacer />
              </v-layout>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { TruckPrefix, countries } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import Autocomplete from "vuejs-auto-complete";
import ErrorBox from "@/components/Common/ErrorBox";
import Loading from "vue-loading-overlay";
import CustomerCurrency from "@/components/Common/CustomerCurrency";
import { StorageKeys } from "../../constants/constants";
import { updateAssignments, milestoneList } from "../../constants/api-urls";
export default {
  data() {
    return {
      workingCountry: null,
      workingCountries: [],
      checklist: [],
      showTracking: "fullTracking",
      vendorId: "",
      lazy: false,
      valid: true,
      countryCodes: [],
      countryCode: "",
      vendorVatNumber: null,
      VendorAddress: null,
      milestone: null,
      milestoneItems: [],
      currentMilestoneId: "",
      currentMilestone: 0,
      milestoneTrack: {
        milestone: [],
        assId: null,
      },
      toggle_exclusive: 0,
      radios: this.$props.bidCurrency,

      assetId: null,
      prefixTrucks: [],
      truckPrefix: this.$props.truckprefix,
      truckNumber: this.$props.truckNo,
      ids: this.$props.assignNumber,
      id: this.$route.params.id,
      error: null,
      loading: false,
      searchText: null,
      driver: {
        id: null,
        name: null,
      },

      AssignmentId: [],
      count: "",

      vendorNames: [],
      vendorName: [],
      VendorNumber: null,
      paymentCurrency: null,
      paymentAmount: this.$props.bidAmount,
      waybillNo: null,
      pickupTime: null,
      assetId: null,
      assets: [],
      currencies: ["AED", "SAR"],
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        vatNumber: (v) =>
          /^([a-zA-Z0-9_-]){1,20}$/.test(v) || "Please Enter Valid Vat Number",
      },
      x: {
        error: null,
      },
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  components: {
    Loading,
    ErrorBox,
    CustomerCurrency,
    Autocomplete,
  },
  props: {
    driverId: String,
    truckprefix: String,
    truckNo: String,
    ownerType: String,
    ownerId: Array,
    isMilestone: Boolean,
    jobCurrency: String,
    driver_id: String,
    driverDetails: Object,
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  created() {
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.radios = key.currency;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      this.radios = val.currency;
    });
    this.$emit("transport-job", this.address);
    this.prefixTrucks = TruckPrefix;
    this.countryCodes = countries;
    const { id = null } = this.$route.params;
    this.id = Number(id);
  },

  methods: {
    getChecklist() {
      if (this.milestone) {
        this.milestoneItems.filter((x) => {
          if (this.milestone == x._id) {
            this.checkList = x.checkList;
          }
        });
      }
    },
    updateMilestone() {
      if (this.milestone) {
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        this.loading = true;
        let url = updateAssignments;
        let body = {
          assId: this.$props.assignNumber.toString() || this.ids,
          milestoneId: this.milestone.toString(),
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            // this.dailog2=false;

            this.loading = false;
          },
          (error) => {
            this.loading = true;
            this.error = error.response.data.message;
          }
        );
      }
    },
    clearMilestone() {
      this.milestone = null;
      this.getMilestones();
    },
    getMilestones() {
      let url = milestoneList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      let body = {
        workingCountry: this.workingCountry,
        assignDriver: "true",
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.milestoneItems = response.data.data;
        },
        (error) => {
          this.error = "Failed to Fetch Milestone Status";
        }
      );
    },
    fetchAssets() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      let data = {
        workingCountry: this.workingCountry,
      };
      this.axios.post(`${apiUrl}/assets/freeAssetsListing`, data, config).then(
        (response) => {
          this.assets = response.data.data;
        },
        (error) => {
          this.loading = false;
          this.error = "Failed to fetch data";
        }
      );
    },
    fetchVendor() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      var data = {
        driverId: this.$props.driverId,
        ownerType: this.$props.ownerType || "Owner",
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/newDriverFlow/vendorDriverTransporter`, data, config)
        .then(
          (response) => {
            if (
              (this.$props.driverDetails.ownerType &&
                this.$props.driverDetails.ownerType.includes("Owner")) ||
              this.$props.driverDetails.ownerType == "Owner"
            ) {
              this.vendorNames = response.data.data.list.filter((x) => {
                if (x.vendorName) {
                  return x;
                } else {
                  if (x.vatNumber) {
                    this.vendorVatNumber = x.vatNumber;
                  }
                  this.vendorName = this.$props.driverDetails.name;
                  this.vendorId = this.driver._id;
                  this.VendorNumber =
                    this.vendorName.VendorNumber ||
                    this.$props.driverDetails.phoneNo;
                  this.countryCode =
                    this.vendorName.vendorCountry ||
                    this.$props.driverDetails.countryCode;
                }
              });
            } else {
              this.vendorNames =
                response.data.data.list[0].transporterData.filter((x) => {
                  if (x.transporterName) {
                    return x;
                  }
                });
            }
          },
          (error) => {
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
    },
    checkTruckPrefix() {
      this.prefixTrucks.forEach((k) => {
        if (k.value != this.$props.truckprefix) {
          this.$props.TruckPrefix = this.truckPrefix;
        }
      });
    },
    checkVendorName() {
      this.VendorNumber =
        this.vendorName.VendorNumber || this.$props.driverDetails.phoneNo;
      this.vendorVatNumber =
        this.vendorName.vendorVatNumber || this.vendorName.vatNumber;
      this.countryCode =
        this.vendorName.vendorCountry || this.$props.driverDetails.countryCode;
      this.VendorAddress =
        this.vendorName.vendorAddress || this.vendorName.address;
      this.vendorId = this.vendorName._id;
      this.error = null;
      this.$refs.form.resetValidation();
    },
    customFilter(item, queryText) {
      const textOne = item.value.toLowerCase();

      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },

    close() {
      this.vendorName = [];
      this.VendorNumber = "";
      this.milestone = null;
      this.ids = "";
      this.paymentAmount = "";
      this.vendorVatNumber = "";
      this.VendorAddress = "";
      this.countryCode = "";
      // this.truckNumber = "";
      // this.truckPrefix = "";
      this.error = "";
      this.$refs.form.resetValidation();
    },
    haserror() {
      return this.error !== null;
    },

    assignDriver() {
      if (this.vendorName.length == 0) {
        this.loading = false;
        this.error = "Please provide vendor's name";
        return false;
      }

      if (!this.vendorName) {
        this.loading = false;
        this.error = "Please provide vendor's name";
        return false;
      }
      if (this.$refs.form.validate()) {
        this.error = null;
        this.loading = true;
        if (!this.paymentAmount || this.paymentAmount <= 0) {
          this.loading = false;
          this.error = "Please provide payment amount above 0";
          return false;
        }
        const data = {
          driverId: this.$props.driverId,
          assignmentId: this.ids.toString(),
          paymentAmount: this.paymentAmount.toString(),
          paymentCurrency: this.radios,
          vendorName: this.vendorName,
          VendorNumber: this.VendorNumber,
          truckNumber: this.truckNumber,
          truckPrefix: this.truckPrefix,
          vendorVatNumber: this.vendorVatNumber
            ? this.vendorVatNumber.trim()
            : "",
          vendorAddress: this.VendorAddress ? this.VendorAddress.trim() : "",
          vendorCountry: this.countryCode.dialing_code || this.countryCode,
          ownerId: this.vendorId,
          ownerType: this.$props.ownerType,
        };
        if (this.jobCurrency != "PAK") {
          if (this.showTracking === "fullTracking") {
            data.isFullTrackingOn = true;
          }
          if (this.showTracking === "onlyCurrentLocation") {
            data.isFullTrackingOn = false;
          }
        }
        if (this.VendorAddress) data["vendorAddress"] = this.VendorAddress;
        if (this.vendorVatNumber)
          data["vendorVatNumber"] = this.vendorVatNumber;
        if (this.$props.ownerType == "Transporter") {
          data.ownerId = this.vendorName._id;
        }
        if (this.$props.ownerType == "Owner") {
          data.ownerId = this.$props.driver_id;
        }

        if (this.assetId) {
          data.assetId = this.assetId.toString();

          let y = this.assets.filter((x) => {
            if (data.assetId === x._id) {
              return x.imeiNumber;
            }
          });

          data.imeiNumber = y[0].imeiNumber;
        }

        if (this.vendorName.vendorName) {
          data.vendorName = this.vendorName.vendorName;
        }
        if (this.vendorName.transporterName) {
          data.vendorName = this.vendorName.transporterName;
        }
        if (this.milestone && this.milestone.length > 0) {
          data.milestoneId = this.milestone;
        }
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        const { apiUrl } = this.constants;
        this.axios
          .post(`${apiUrl}/newDriverFlow/upperNewAssignDriver`, data, config)
          .then(
            (response) => {
              this.dialog = false;
              const { data } = response.data;
              this.$eventBus.$emit("assignedDriver", this.$props.driverId);
              //    this.$emit("bid-success", this.address);
              //  this.track("Bid Accepted", {
              //   "Job Id": this.address.inquiryId,
              //   "Customer Name": this.address.customer,
              //   "Job Start Date":this.address.startDate,
              //   "Working Country":this.address.startDateworkingCountry,

              // });
              this.vendorName = [];
              this.VendorNumber = "";
              this.vendorVatNumber = "";
              (this.VendorAddress = ""),
                (this.countryCode = ""),
                (this.paymentAmount = "");
              this.truckNumber = "";
              this.truckPrefix = "";
              this.ids = "";
              this.AssignmentId = [];
              this.$refs.form.resetValidation();
              this.$props.isAssigned = true;
              this.loading = false;
            },
            (error) => {
              this.error = error.response.data.message;
              this.dailog = false;
              this.loading = false;
            }
          );
      }
    },
    assignmentList() {
      this.error = null;
      this.loading = true;

      const data = {
        jobId: this.$route.params.id,
        tabOrder: this.$route.params.tabOrder || "1",
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(
          `${apiUrl}/dmsAdmin/assignmentsForNewAssignTransporter`,
          data,
          config
        )
        .then(
          (response) => {
            const { data } = response.data.data;
            this.count = response.data.data.count;
            if (!this.count || this.count == 0) {
              this.dialog = false;
              this.$emit("assign-count", true);
              return;
            }
            this.dialog = true;
            data.forEach((e) => {
              this.AssignmentId.push({
                value: e.assignmentId,
                text: e.assignmentId,
              });
            });
            this.loading = false;
          },
          (error) => {
            this.error = error.response.data.message;
            this.dailog = false;
            this.loading = false;
          }
        );
    },
  },
};
</script>
<style scoped>
.reset-pos {
  position: relative;
  top: 9px;
}
.postion-set {
  word-break: break-all;
}
.cont {
  width: 100%;
  position: relative;
  z-index: 1;
}
.timelineHori {
  counter-reset: step;
  display: flex;
  overflow: auto;
  overflow-y: hidden;
}
.timelineHori li:before {
  content: "1";
  width: 30px;
  height: 30px;
}
.timelineHori li:before {
  content: "1";
  width: 30px;
  height: 30px;
  border: 2px solid white;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  color: white;
  text-align: center;
  font-weight: bold;
}
.timelineHori li:first-child:after {
  content: none;
}

.timelineHori li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  background: orange;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.timelineHori li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: orange;
  color: white;
  text-align: center;
  font-weight: bold;
}
.timelineHori li {
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
  list-style-type: none;
}
.assign-pop {
  padding: 10px 40px 10px 40px;
  overflow-y: scroll !important;
  height: calc(100vh - 100px) !important;
}
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.assignment {
  text-align: center;
  font-weight: bolder;
  font-size: large;
}
.ccc.theme--light.v-input:not(.v-input--is-disabled) input,
.theme--light.v-input:not(.v-input--is-disabled) textarea {
  color: rgba(0, 0, 0, 0.54);
}
</style>
<style lang="scss">
.timelineHori {
  counter-reset: step;
  display: flex;
  overflow: auto;
  padding-top: 20px;
  overflow-y: hidden;
  &:-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px lightgray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: lightgray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray !important;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    position: absolute;
  }
}
</style>
