<template>
  <div class="log">
    <v-btn
      @click.stop="
        assigments();
        getMilestones();
      "
      color="orange darken-3"
      :disabled="loading"
      class="white--text"
      >Assign</v-btn
    >
    <v-dialog
      v-model="dialog"
      class="boxheight"
      max-width="55%"
      max-height="65%"
      persistent
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title> Assign Transporter</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false" @click="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-flex pt-1 class="assignment"
            >Assignment Left - {{ this.count }}</v-flex
          >
          <v-spacer style="padding: 30px">
            <v-card-text class="nospace">
              <v-layout align-baseline row wrap>
                <v-flex xs6 class>
                  <v-select
                    :items="AssignmentId"
                    label="Assignment Id"
                    v-model="ids"
                    multiple
                    class="pt-0 currencyTitle"
                    :rules="[rules.required, rules.requiredLocation]"
                    single-line
                  ></v-select>
                </v-flex>
                <v-flex xs6 class="pl-2">
                  <v-text-field
                    class="pt-0 currencyTitle"
                    :suffix="this.radios"
                    label="Transporter's rate per assignment"
                    type="number"
                    single-line
                    v-model="paymentAmount"
                    :value="paymentAmount"
                    :rules="[rules.required, rules.number]"
                  />
                </v-flex>
                <v-flex xs6 md6 style v-if="!details.address">
                  <v-text-field
                    label="Vendor's Address"
                    type="text"
                    v-model="VendorAddress"
                    :value="VendorAddress"
                    :rules="[rules.required]"
                    single-line
                  />
                </v-flex>
                <v-flex xs6 md6 style v-if="!details.vatNumber">
                  <v-text-field
                    style="padding-left: 10px"
                    :label="
                      radios != 'PKR'
                        ? 'Vendor VAT Number*'
                        : 'Vendor STRN Number*'
                    "
                    type="text"
                    v-model="vendorVatNumber"
                    :value="vendorVatNumber"
                    :rules="radios != 'PKR' ? [] : [rules.required]"
                    counter
                    single-line
                    maxlength="20"
                  />
                </v-flex>
                <v-flex md12 v-if="!isMilestone">
                  <v-layout
                    row
                    v-if="this.milestoneTrack.milestone.length == 0"
                  >
                    <v-flex md11>
                      <v-select
                        v-model="milestone"
                        :items="milestoneItems"
                        item-text="pickupLocation"
                        item-value="_id"
                        label="Select Milestone"
                        persistent-hint
                        @change="getChecklist"
                        single-line
                      >
                        <template slot="item" slot-scope="data"
                          >{{ data.item.pickupLocation }} -
                          {{ data.item.dropOffLocation }}</template
                        >
                      </v-select>
                    </v-flex>
                    <v-flex md1>
                      <v-btn icon @click="clearMilestone">
                        <v-icon class="heading grey--text text--darken-4"
                          >close</v-icon
                        >
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-divider v-if="milestone"></v-divider>
                  <v-layout v-if="milestone">
                    <v-flex
                      xs12
                      md12
                      class="heading orange--text"
                      style="text-align: initial"
                    >
                      Milestone Information <br />
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="milestone">
                    <v-flex xs12 md12 class="cont">
                      <ul class="timelineHori">
                        <li v-for="(item, i) in checkList" :key="i">
                          <span class="postion-set">{{ item.name }}</span>
                        </li>
                      </ul>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <div
                    class="my-3"
                    v-if="haserror"
                    style="
                      color: red;
                      width: 50%;
                      border: 0px;
                      margin: auto;
                      text-align: center;
                    "
                  >
                    {{ error }}
                  </div>
                </v-flex>

                <v-flex xs12 style="text-align: center">
                  <v-btn
                    color="orange darken-3"
                    class="white--text"
                    @click="assignTransporter()"
                    >Assign</v-btn
                  >
                </v-flex>

                <v-spacer />
              </v-layout>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { TruckPrefix, countries } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import Autocomplete from "vuejs-auto-complete";
import ErrorBox from "@/components/Common/ErrorBox";
import Loading from "vue-loading-overlay";
import CustomerCurrency from "@/components/Common/CustomerCurrency";
import { StorageKeys } from "../../constants/constants";
import { updateAssignments, milestoneList } from "../../constants/api-urls";
export default {
  data() {
    return {
      workingCountry: null,
      workingCountries: [],
      showTracking: "fullTracking",
      vendorId: "",
      lazy: false,
      valid: true,
      countryCodes: [],
      countryCode: "",
      vendorVatNumber: null,
      VendorAddress: null,
      milestone: null,
      checkList: [],
      milestoneItems: [],
      currentMilestoneId: "",
      currentMilestone: 0,
      milestoneTrack: {
        milestone: [],
        assId: null,
      },
      toggle_exclusive: 0,
      radios: this.$props.bidCurrency,

      assetId: null,
      prefixTrucks: [],
      truckPrefix: this.$props.truckprefix,
      truckNumber: this.$props.truckNo,
      ids: [],
      id: this.$route.params.id,
      error: null,
      loading: false,
      searchText: null,
      AssignmentId: [],
      count: "",
      VendorNumber: null,
      paymentCurrency: null,
      paymentAmount: this.$props.bidAmount,
      waybillNo: null,
      pickupTime: null,
      currencies: ["AED", "SAR"],
      rules: {
        required: (value) => !!value || "This field is required.",
        requiredLocation: (value) =>
          !!value.length || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Rate",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        vatNumber: (v) =>
          /^([a-zA-Z0-9_-]){1,20}$/.test(v) || "Please Enter Valid Vat Number",
      },
      x: {
        error: null,
      },
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
    };
  },
  components: {
    Loading,
    ErrorBox,
    CustomerCurrency,
    Autocomplete,
  },
  props: {
    ownerType: String,
    ownerId: String,
    jobCurrency: String,
    isMilestone: Boolean,
    details: Object,
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  created() {
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.radios = key.currency;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      this.radios = val.currency;
    });
    this.$emit("transport-job", this.address);
    this.prefixTrucks = TruckPrefix;
    this.countryCodes = countries;
    const { id = null } = this.$route.params;
    this.id = Number(id);
  },

  methods: {
    getChecklist() {
      if (this.milestone) {
        this.milestoneItems.filter((x) => {
          if (this.milestone == x._id) {
            this.checkList = x.checkList;
          }
        });
      }
    },
    updateMilestone() {
      if (this.milestone) {
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        this.loading = true;
        let url = updateAssignments;
        let body = {
          assId: this.$props.assignNumber.toString() || this.ids,
          milestoneId: this.milestone.toString(),
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.loading = false;
          },
          (error) => {
            this.loading = true;
            this.error = error.response.data.message;
          }
        );
      }
    },
    clearMilestone() {
      this.milestone = null;
      this.getMilestones();
    },
    getMilestones() {
      let url = milestoneList;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
      }
      let body = {
        workingCountry: this.workingCountry,
        assignDriver: "true",
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.milestoneItems = response.data.data;
        },
        (error) => {
          this.error = "Failed to Fetch Milestone Status";
        }
      );
    },

    customFilter(item, queryText) {
      const textOne = item.value.toLowerCase();

      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    assigments() {
      this.truckPrefix = this.$props.truckprefix;
      this.truckNumber = this.$props.truckNo;
      this.loading = true;
      const data = {
        jobId: this.$route.params.id,
        tabOrder: this.$route.params.tabOrder || "1",
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(
          `${apiUrl}/dmsAdmin/assignmentsForNewAssignTransporter`,
          data,
          config
        )
        .then(
          (response) => {
            const { data } = response.data;
            this.count = response.data.data.count;
            if (!this.count || this.count == 0) {
              this.dialog = false;
              this.$emit("assign-count", true);
              return;
            }
            this.dialog = true;
            response.data.data.data.forEach((e) => {
              this.AssignmentId.push({
                value: e.assignmentId,
                text: e.assignmentId,
              });
            });
            this.loading = false;
          },
          (error) => {
            this.error = error.response.data.message;
            this.dailog = false;
            this.loading = false;
          }
        );
    },
    close() {
      this.vendorName = [];
      this.VendorNumber = "";
      this.milestone = null;
      this.ids = [];
      this.paymentAmount = "";
      this.vendorVatNumber = "";
      this.VendorAddress = "";
      this.countryCode = "";
      this.error = "";
      this.$refs.form.resetValidation();
    },
    haserror() {
      return this.error !== null;
    },
    assignTransporter() {
      if (this.$refs.form.validate()) {
        this.error = null;
        this.loading = true;
        if (!this.paymentAmount || this.paymentAmount <= 0) {
          this.loading = false;
          this.error = "Please provide payment amount above 0";
          return false;
        }
        if (!this.ids.length) {
          this.loading = false;
          this.error = "Please select assignment id";
          return false;
        }
        const data = {
          transporterId: this.$props.details._id,
          assignmentId: this.ids,
          paymentAmount: this.paymentAmount.toString(),
          paymentCurrency: this.radios,
        };

        if (this.vendorVatNumber) {
          data.vendorVatNumber = this.vendorVatNumber;
        }
        if (this.VendorAddress) {
          data.vendorAddress = this.VendorAddress;
        }
        if (this.milestone && this.milestone.length > 0) {
          data.milestoneId = this.milestone;
        }
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        const { apiUrl } = this.constants;
        this.axios
          .post(
            `${apiUrl}/newDriverFlow/upperNewAssignTransporter`,
            data,
            config
          )
          .then(
            (response) => {
              this.dialog = false;
              const { data } = response.data;
              this.$eventBus.$emit("assignedDriver", this.$props.driverId);
              this.vendorVatNumber = "";
              (this.VendorAddress = ""), (this.paymentAmount = "");
              this.ids = [];
              this.AssignmentId = [];
              this.$refs.form.resetValidation();
              this.$props.isAssigned = true;
              this.loading = false;
            },
            (error) => {
              this.error = error.response.data.message;
              this.dailog = false;
              this.loading = false;
            }
          );
      }
    },
    assignmentList() {
      this.error = null;
      this.loading = true;
      const data = {
        JobId: this.$route.params.id,
      };
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/dmsAdmin/assignments`, data, config).then(
        (response) => {
          this.dialog = false;
          const { data } = response.data.data;
          response.data.data.forEach((e) => {
            this.AssignmentId.push({
              value: e.assignmentId,
              text: e.assignmentId,
            });
          });
          this.loading = false;
        },
        (error) => {
          this.error = error.response.data.message;
          this.dailog = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style lang="scss">
.timelineHori {
  counter-reset: step;
  display: flex;
  overflow: auto;
  padding-top: 20px;
  overflow-y: hidden;
  &:-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px lightgray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: lightgray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray !important;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    position: absolute;
  }
}
.boxheight {
  max-height: 65%;
}
</style>
<style scoped>
.postion-set {
  word-break: break-all;
}
.cont {
  width: 100%;
  position: relative;
  z-index: 1;
}
.timelineHori {
  counter-reset: step;
  display: flex;
  overflow: auto;
  overflow-y: hidden;
}
.timelineHori li:before {
  content: "1";
  width: 30px;
  height: 30px;
}
.timelineHori li:before {
  content: "1";
  width: 30px;
  height: 30px;
  border: 2px solid white;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  color: white;
  text-align: center;
  font-weight: bold;
}
.timelineHori li:first-child:after {
  content: none;
}

.timelineHori li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 6px;
  background: orange;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.timelineHori li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: orange;
  color: white;
  text-align: center;
  font-weight: bold;
}
.timelineHori li {
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
  list-style-type: none;
}
element.style {
  max-width: 50%;
}

.assign-pop {
  padding: 10px 40px 10px 40px;
  overflow-y: scroll !important;
  height: calc(100vh - 100px) !important;
}
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.assignment {
  text-align: center;
  font-weight: bolder;
  font-size: large;
}
.ccc.theme--light.v-input:not(.v-input--is-disabled) input,
.theme--light.v-input:not(.v-input--is-disabled) textarea {
  color: rgba(0, 0, 0, 0.54);
}
</style>
