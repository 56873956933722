<template>
  <v-container fluid>
    <v-card raised>
      <loading :active.sync="loading" />

      <v-tabs v-model="TabStatus" fixed-tabs background-color="darkorange" dark>
        <v-tab :href="`#ViewBids`" v-if="isBid" @click="viewTab('ViewBids')"
          >View Bids</v-tab
        >
        <v-tab :href="`#transporter`" @click="viewTab('transporter')"
          >Transporters</v-tab
        >
        <v-tab :href="`#assignDriver`" @click="viewTab('assignDriver')"
          >Owner drivers</v-tab
        >
        <v-tab
          :href="`#transporterDriver`"
          @click="viewTab('transporterDriver')"
          >Transporter's Driver</v-tab
        >
      </v-tabs>
      <v-flex
        :href="`#assignDriver`"
        class="m-t-20"
        v-if="currentTab == 'assignDriver'"
        md12
      >
        <filterdriverslist />
      </v-flex>
      <v-flex
        :href="`#transporterDriver`"
        class="m-t-20"
        v-if="currentTab == 'transporterDriver'"
        md12
      >
        <transporterDriverList />
      </v-flex>
      <v-flex
        :href="`#transporter`"
        class="m-t-20"
        v-if="currentTab == 'transporter'"
        md12
      >
        <transportersList />
      </v-flex>

      <v-flex
        :href="`#ViewBids`"
        class="m-t-20"
        v-if="currentTab == 'ViewBids'"
        md12
      >
        <ViewBids />
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import filterdriverslist from "./filterdriverslist";
import transporterDriverList from "./transporterDriverList";
import transportersList from "./transportersList";
import Loading from "vue-loading-overlay";
import { StorageKeys } from "../../constants/constants";
import logistics from "./logistics";
import ViewBids from "./ViewBids";
import moment from "moment";
import { EventBus } from "../../event-bus.js";
export default {
  created() {
    localStorage.removeItem("subRealBook");
    localStorage.removeItem("filterType");
    localStorage.removeItem("subGeoType");
    localStorage.removeItem("subJobMode");
    localStorage.removeItem("subJobTypePro");
    localStorage.removeItem("salesJobType");
    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "driverfilters") {
        this.$router.push(`/jobs`);
      }
    });
    const { id = null } = this.$route.params;
    this.id = Number(id);
    if (this.$route.query.x) {
      this.isBid = true;
      this.currentTab = "ViewBids";
      this.TabStatus = "ViewBids";
    }
  },
  components: {
    filterdriverslist,
    logistics,
    ViewBids,
    Loading,
    transporterDriverList,
    transportersList,
  },
  data() {
    return {
      loading: false,
      currentTab: "assignDriver",
      TabStatus: "assignDriver",
      isBid: false,
    };
  },
  props: {
    jobId: Number,
    _id: String,
  },

  methods: {
    viewTab(flag) {
      this.currentTab = flag;
    },

    getTime(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
  },
};
</script>

<style></style>
